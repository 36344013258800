export type ToCurrencyPayload = {
  value: number;
  locale?: string;
  currency?: string;
  precision?: number;
};

export const toCurrency = (payload: ToCurrencyPayload): string => {
  return new Intl.NumberFormat(payload.locale ? payload.locale : 'en-GB', {
    style: 'currency',
    currency: payload.currency ? payload.currency : 'GBP',
    minimumFractionDigits: payload.precision ? payload.precision : 2
  }).format(payload.value);
};
